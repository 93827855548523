<template>
    <div>
        <div class="page">
            <div class="page_header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>定价</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ s === 1 ? "分类" : s === 2 ? "添加一级分类" : "编辑一级分类" }} </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="page_headerSecondary">
                <div></div>
                <div>
                    <VyAddBtn @click.native="s = 2" v-show="s === 1" />
                    <!-- <button @click="s = 2" v-show="s === 1">添加一级分类</button> -->
                </div>
            </div>
            <!-- 列表 -->
            <div class="page_content" v-show="s === 1">
                <el-table
                    :data="tableData"
                    border
                    style="width: 100%"
                    row-key="id"
                    :tree-props="{ children: 'second' }"
                    @row-click="handleRowClick"
                    ref="tab"
                >
                    <el-table-column prop="name" align="center" label="分类名"> </el-table-column>
                    <el-table-column prop="oldprice" align="center" label="原价"> </el-table-column>
                    <el-table-column prop="newprice" align="center" label="现价"> </el-table-column>
                    <el-table-column prop="hot_status" align="center" label="活动状态">
                        <template slot-scope="{ row }">
                            <span :style="{ color: row.hot_status ? '#b66dff' : '#fe7c96' }">{{
                                row.hot_status ? "是" : "否"
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" align="center" label="状态">
                        <template slot-scope="{ row }">
                            <span :style="{ color: row.status ? '#b66dff' : '#fe7c96' }">{{ row.status ? "显示" : "隐藏" }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" align="center" label="操作">
                        <template slot-scope="scope">
                            <div class="btns">
                                <div v-if="!scope.row.third">
                                    <!-- <button @click="add(scope.row.id)">添加二级分类</button> -->
                                    <VyAddBtn @click.native="add(scope.row.id)" btnType="二级" />
                                </div>
                                <div class="_delete">
                                    <button @click="del(scope.row)">删除</button>
                                </div>
                                <div class="_operate">
                                    <button @click="edit(scope.row)">编辑</button>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 添加一级分类 -->
            <add v-show="s === 2" @stop="stop"></add>
            <!-- 编辑一级分类 -->
            <edit v-show="s === 3" @stop="stop" :id="id"></edit>
        </div>

        <!-- 添加二级分类弹框 -->
        <el-dialog title="添加二级分类" :visible.sync="dialogStatus" width="40%" :show-close="false" :before-close="bfc">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="分类名">
                    <el-input v-model.trim="form.name"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch
                        v-model="form.status"
                        active-text="显示"
                        inactive-text="隐藏"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                    ></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogStatus = false">取 消</el-button>
                <el-button type="primary" @click="yes_add">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 编辑二级分类弹框 -->
        <el-dialog title="编辑二级分类" :visible.sync="dialogStatus_" width="40%" :show-close="false" :before-close="bfc">
            <el-form ref="form" :model="form_" label-width="80px">
                <el-form-item label="分类名">
                    <el-input v-model.trim="form_.name"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch
                        v-model="form_.status"
                        active-text="显示"
                        inactive-text="隐藏"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                    ></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogStatus_ = false">取 消</el-button>
                <el-button type="primary" @click="yes_edit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import add from "./add.vue";
import edit from "./edit.vue";
import axios from "axios";
import qs from "qs";
import VyAddBtn from "@/components/customs/VyAddBtn.vue";

export default {
    components: { add, edit, VyAddBtn },
    data() {
        return {
            tableData: [],
            // 1列表 2添加一级分类 3编辑一级分类
            s: 1,
            // --
            form: {
                name: "",
                pid: "",
                status: true,
            },
            dialogStatus: false,
            form_: {
                name: "",
                pid: "",
                status: true,
            },
            dialogStatus_: false,
            // 编辑一级分类时传入的id
            id: "",
        };
    },
    mounted() {
        this.onLoading();
    },
    methods: {
        async getData() {
            let { data } = await axios.get("/api/price/show");
            return data;
        },
        async onLoading() {
            this.tableData = await this.getData();
        },
        // 从添加或编辑取消返回至列表
        stop() {
            this.s = 1;
            this.id = "";
            this.onLoading();
        },
        // 点击添加二级分类打开弹框
        add(id) {
            this.form.pid = id;
            this.dialogStatus = true;
        },
        // 确认添加二级分类
        async yes_add() {
            if (!this.form.name) return this.$message.error("分类名不能为空!");
            let { data } = await axios.post(
                "/api/price/sadd",
                qs.stringify({
                    ...this.form,
                })
            );
            this.dialogStatus = false;
            this.form = {
                name: "",
                pid: "",
                status: true,
            };

            this.onLoading();
        },
        // 点击编辑按钮
        edit(row) {
            if (row.second) {
                //  编辑一级分类
                this.id = row.id;
                this.s = 3;
            } else if (row.third) {
                //  编辑二级分类

                // 查找到需要编辑的二级分类
                let { id, status, name } = this.tableData.find(el => el.id === row.pid).second.find(el => el.id === row.id);
                this.form_ = { id, name, status: Boolean(status) };
                this.dialogStatus_ = true;
            }
        },
        // 确认二级分类编辑
        async yes_edit() {
            if (!this.form_.name) return this.$message.error("分类名不能为空!");
            let { data } = await axios.put(
                "/api/price/supdate",
                qs.stringify({
                    ...this.form_,
                })
            );
            if (data.code !== 200) {
                this.$message.error("编辑失败!");
            } else {
                this.$message.success("编辑成功!");
            }
            this.dialogStatus_ = false;
            this.onLoading();
        },
        // 删除分类
        async del(row) {
            let { id } = row;
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch(() => false);
            if (!s) return;
            if (row.second) {
                // 删除一级
                if (row.second.length !== 0) return this.$message.error("无法直接删除有子分类的分类!");
                let { data } = await axios.delete("/api/price/fdelete", { params: { id } });
                if (data.code !== 200) return this.$message.error("删除失败");
                this.$message.success("删除成功");
            } else if (row.third) {
                // 删除二级
                if (row.third.length !== 0) return this.$message.error("无法直接删除有子分类的分类!");
                let { data } = await axios.delete("/api/price/sdelete", { params: { id } });
                if (data.code !== 200) return this.$message.error("删除失败");
                this.$message.success("删除成功");
            }
            this.onLoading();
        },
        // 点击行
        handleRowClick(row, column, event) {
            if (event.target.tagName === "BUTTON") return;
            this.$refs["tab"].toggleRowExpansion(row);
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_headerSecondary {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
        .btns {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}
/deep/.el-switch__label.is-active {
    color: #7781f1;
}
</style>
